import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, Link, ListItemText } from "@mui/material";
//
import Iconify from "../../Iconify";
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from "./style";
import { isExternalLink } from "..";
import useAuth from "src/hooks/useAuth";
import LoadingScreen from "src/components/LoadingScreen";

// ----------------------------------------------------------------------

NavItemRoot.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemRoot({
  item,
  isCollapse,
  open = false,
  active,
  onOpen,
}) {
  const {
    title,
    path,
    icon,
    info,
    children,
    is_holdingTank,
    investor_portal,
    is_investor,
  } = item;
  const { isSubAdmin, user, isAdmin } = useAuth();
  // const menuDisabled = user?.holding_tank === 1 && is_holdingTank === false;
  if (user?.holding_tank === 1 && !isAdmin && !isSubAdmin) {
    if (!is_holdingTank) return null;
  }
  if (!isAdmin) {
    if (user?.user_type === undefined || user?.user_type === null) {
      return <LoadingScreen />;
    }
  }

  if (
    user?.user_type === "investor" ||
    (user?.user_type === "amc" && !isAdmin && !isSubAdmin)
  ) {
    if (!investor_portal) return null;
  }
  if (
    user?.user_type !== "investor" &&
    user?.user_type !== "amc" &&
    !isAdmin &&
    !isSubAdmin
  ) {
    if (is_investor) return null;
  }

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle
        disableTypography
        primary={title}
        isCollapse={isCollapse}
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeRoot={active}>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

NavItemSub.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemSub({ item, open = false, active = false, onOpen }) {
  const {
    title,
    path,
    info,
    children,
    is_holdingTank,
    is_investor,
    investor_portal,
  } = item;
  const { user, isAdmin, isSubAdmin } = useAuth();
  if (user?.holding_tank === 1 && !isAdmin && !isSubAdmin) {
    if (!is_holdingTank) return null;
  }

  if (
    user?.user_type === "investor" ||
    (user?.user_type === "amc" && !isAdmin && !isSubAdmin)
  ) {
    if (!investor_portal) return null;
  }
  if (
    user?.user_type !== "investor" &&
    user?.user_type !== "amc" &&
    !isAdmin &&
    !isSubAdmin
  ) {
    if (is_investor) return null;
  }

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography primary={title} />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeSub={active} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      subItem
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: "50%",
          bgcolor: "text.disabled",
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: "scale(2)",
            bgcolor: "primary.main",
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

ArrowIcon.propTypes = {
  open: PropTypes.bool,
};

export function ArrowIcon({ open }) {
  return (
    <Iconify
      icon={open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
